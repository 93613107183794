import '../../css/background.css'

const mapURL = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10357.20271783878!2d17.5976612!3d49.5354769!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713b07e7d2eb269%3A0x1cf4a263eec16fdd!2sVenedik%2C%20751%2031%20Lipn%C3%ADk%20nad%20Be%C4%8Dvou!5e0!3m2!1scs!2scz!4v1709849743682!5m2!1scs!2scz";

export const Contact = () => {
    return (
        <div className='contact_background flex flex-col md:flex-row py-10 items-center'>
            <div className='flex flex-col text-white md:w-1/2 text-center text-xl md:text-2xl space-y-2'>
                <div>Zahra Sweets s.r.o.</div>
                <div>+420 739 300 155</div>
                <div>mirek.zatloukal@seznam.cz</div>
                <div>Venedik, Lipník nad Bečvou 751 31</div>
                <div>IČ: 02585227</div>
                <div>DIČ: CZ02585227</div>
            </div>
            <div className='w-full mt-10 md:mt-0 md:w-1/2 flex justify-center'>
                <div className='aspect-square w-3/4 items-center'>
                    <iframe
                        className='rounded-lg'
                        title='map'
                        src={mapURL}
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen={true}
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}
