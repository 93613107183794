import { useParams } from 'react-router';
import '../css/image.css'
import { Product } from '../models/product';
import { ProductItem } from '../components/products/product';
import products from '../assets/products.json'
import categories from '../assets/categories.json'
import { Category } from '../models/category';
import { Link } from 'react-router-dom';

export const ProductPage = () => {
    const { id } = useParams<{ id: string }>();
    const product: Product | undefined = products.find(product => product.id === +(id ?? "0"));
    const category: Category | undefined = categories.find(category => category.id === product?.categoryId);
    const similarProducts: Product[] = products.filter(simProduct => product?.categoryId === simProduct.categoryId ?? 0);

    if (!product || !category) {
        return (
            <div>
                404
            </div>
        )
    }

    return (
        <div className='flex flex-col gradient-to-center'>
            <div className="w-full h-[20vh]">
                <img src={process.env.PUBLIC_URL + category.images[0]} alt="bg" className="w-full h-full object-cover" />
            </div>
            <div className='mt-10'>
                <ProductItem product={product} category={category?.name} />
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 px-10 my-10 gap-5">
                {
                    similarProducts.slice(0, 5).map((product, key) => (
                        <SimilarProduct product={product} key={key} />
                    ))
                }
            </div>
            <div className="w-full h-[20vh]">
                <img src={process.env.PUBLIC_URL + category.images[1]} alt="bg" className="w-full h-full object-cover" />
            </div>
            <div className='flex bg-[#3719EB] text-white text-4xl p-5'>
                Zahra Sweets s.r.o.
            </div>
        </div>
    )
}

interface SimilarProductProps {
    product: Product,
}

export const SimilarProduct: React.FC<SimilarProductProps> = ({ product }) => {
    return (
        <Link to={"/products/" + product.id}>
            <div className='border-[5px] border-[#3719EB] bg-[#FFFFFF]'>
                <div className='flex justify-center'>
                    <img src={process.env.PUBLIC_URL + product.image} alt={product.name} className='w-1/3 mb-2 mt-4' />
                </div>
                <div className='text-center mb-2'>
                    {product.name}
                </div>
            </div>
        </Link>
    )
}