import { Product } from "../../models/product"

interface ProductItemProps {
    product: Product,
    category: string,
}

export const ProductItem: React.FC<ProductItemProps> = ({ product, category }) => {
    return (
        <div className="w-full flex flex-col lg:flex-row lg:space-x-16">
            <div className="hidden w-1/2 lg:flex justify-end">
                <div className="w-80">
                    <ProductImage product={product} />
                </div>
            </div>
            <div className='flex flex-col justify-start px-8 lg:w-1/2'>
                <div className="flex">
                    <div className="w-full lg:w-auto text-center text-4xl lg:text-5xl border-[#3719EB] border-t-[15px] border-r-[15px] lg:border-t-[20px] lg:border-r-[20px] p-5 rounded-full">
                        {product.name}
                    </div>
                </div>
                <div className="flex lg:hidden p-5 mt-5 mx-auto lg:justify-end">
                    <ProductImage product={product} />
                </div>
                <div className='flex flex-col lg:p-5 items-center lg:items-start text-xl space-y-2 mt-10'>
                    <div className="flex space-x-5 lg:space-x-10">
                        <Column values={["Hmotnost:", "Kategorie:", "Katalogové číslo:"]} />
                        <Column values={[product.weight + "g", category, product.catalogId + ""]} />
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ColumnProps {
    values: string[],
}

export const Column: React.FC<ColumnProps> = ({ values }) => {
    return (
        <div className="flex flex-col">
            {
                values.map((value, key) => (
                    <div key={key} className="border-b-4 border-[#3719EB] last:border-b-0 py-3">
                        {value}
                    </div>
                ))
            }
        </div>
    )
}

interface ProductImageProps {
    product: Product,
}

export const ProductImage: React.FC<ProductImageProps> = ({ product }) => {
    return (
        <div className="relative flex bg-[#FFFFFF] border-[7px] border-[#3719EB] z-20">
            {/* <div className='left_border'></div> */}
            <img src={process.env.PUBLIC_URL + product.image} alt={product.name} className="rounded-xl p-5 object-contain" />
            {/* <div className='bottom_border'></div>
            <div className='right_border'></div>
            <div className='up_border'></div> */}
        </div>
    )
}

