import { useState } from 'react'
import { MenuButton, MenuHoverButton } from './menuButton'
import Hamburger from 'hamburger-react'

export const Header = () => {
    const [isOpen, setOpen] = useState(true);

    return (
        <div className="flex flex-col bg-[#E7E7E7]">
            <div className='w-full bg-[#3719EB] h-5'></div>
            <div className='flex flex-col md:flex-row w-full justify-between pt-6 pb-6'>
                <div>
                    <img className='w-4/5 md:w-1/4 mx-auto' src={process.env.PUBLIC_URL + "/logo1.png"} alt='logo' />
                </div>
                <div className='flex-1 flex justify-center items-center mt-3 md:mt-0'>
                    <div className='flex flex-col text-center text-md md:text-xl md:space-y-1'>
                        <div>
                            ZahraSweets s.r.o.
                        </div>
                        <div>
                            +420 739 300 155
                        </div>
                        <div>
                            mirek.zatloukal@seznam.cz
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative z-10 flex flex-col md:flex-row bg-[#3719EB] md:space-x-10 justify-center sticky'>
                <div className='md:hidden'>
                    <Hamburger toggled={isOpen} toggle={setOpen} color='white'  />
                </div>
                {isOpen && <MenuButton label='Home' path='/' /> } 
                {/* {isOpen && <MenuButton label='Produkty' path='/#products' /> }  */}
                {isOpen && <MenuHoverButton label='Produkty' path='/#products' /> } 
                {isOpen && <MenuButton label='O nás' path='/#aboutus' /> } 
                {isOpen && <MenuButton label='Kontakt' path='/#contact' /> } 
                {isOpen && <a href={process.env.PUBLIC_URL + "/katalog.pdf"} target="_blank" rel="noopener noreferrer">
                    <button className="py-3 text-2xl text-white w-full">
                        Katalog
                    </button>
                </a> } 
            </div>
        </div>
    )
}
