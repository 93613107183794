import { Header } from "./header/header"

export const Layout = (props: { children: React.ReactNode }) => {
    return (
        <div className="h-full w-full">
            <Header />
            <div className="flex flex-col w-full h-full">
                {props.children}
            </div>
            {/* <div className='flex bg-[#3719EB] text-white text-4xl p-5'>
                Zahra Sweets s.r.o.
            </div> */}
        </div>
    )
}