import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Layout } from './components/layout';
import { HomePage } from './pages/homePage';
import { ProductPage } from './pages/productPage';
import { CategoryPage } from './pages/categoryPage';

function App() {
  return (
    <div className="w-screen h-screen">
      <Router>
        <Layout>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/products/:id' element={<ProductPage />} />
            <Route path='/categories/:id' element={<CategoryPage />} />
          </Routes>
        </Layout>
      </Router>
    </div>

  );
}

export default App;
