import { useState } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import categoriesImport from '../../assets/categories.json'
import { Category } from '../../models/category';

interface MenuButtonProps {
    label: string,
    path: string,
}

export const MenuButton: React.FC<MenuButtonProps> = ({ label, path }) => {
    return (
        <NavHashLink to={path}>
            <button className="py-3 px-3 text-2xl text-white w-full text-center whitespace-nowrap">
                {label}
            </button>
        </ NavHashLink>
    )
}

interface MenuHoverButtonProps {
    label: string,
    path: string,
}

export const MenuHoverButton: React.FC<MenuHoverButtonProps> = ({ label, path }) => {
    const categories: Category[] = categoriesImport;
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="relative" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <NavHashLink to={path}>
                <button className="py-3 text-2xl text-white w-full text-center">
                    {label}
                </button>
            </NavHashLink>
            {
                isOpen &&
                <div className="origin-top-right md:absolute md:left-1/2 md:-translate-x-1/2 rounded-b-lg shadow-lg bg-[#3719EB]">
                    <div className="py-1 rounded-md bg-[#3719EB] shadow-xs">
                        {
                            categories.map((category, index) => (
                                <MenuButton label={category.name} path={"/categories/" + category.id} key={index} />
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    );
};