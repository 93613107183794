import { AboutUs } from '../components/homepage/aboutUs'
import { Presentation } from '../components/homepage/presentation'
import categoriesAssets from '../assets/categories.json'
import { Category } from '../models/category'
import { CategoryItem } from '../components/homepage/categoryItem'
import { Contact } from '../components/homepage/contact'
import '../css/headline.css'

export const HomePage = () => {
    const categories: Category[] = categoriesAssets

    return (
        <div className='flex flex-col'>
            <div className="w-full h-[25vh] lg:h-[50vh]">
                <img src={process.env.PUBLIC_URL + "/bg1.png"} alt="bg1" className="w-full h-full object-cover" />
            </div>
            <div className='py-5 lg:py-10 bg-[#E7E7E7]'>
                <Presentation />
            </div>
            <div className='' id="aboutus">
                <AboutUs />
            </div>
            <div className='flex flex-col space-y-5 lg:space-y-10 py-10 bg-[#E7E7E7]' id="products">
                <div className='text-4xl lg:text-6xl mx-auto'>
                    Naše produkty
                </div>
                {
                    categories.map((value, index) =>
                        <CategoryItem category={value} index={index} key={index} />
                    )
                }
            </div>
            <div id="contact">
                <Contact />
            </div>
        </div>
    )
}