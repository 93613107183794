import { useEffect, useState } from "react"
import Categories from '../../assets/categories.json'
import { Category } from "../../models/category";

export const Presentation = () => {
    const [slide, setSlide] = useState(1);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSlide((prevSlide) => prevSlide < 5 ? prevSlide + 1 : 1);
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="flex flex-col">
            <Slide slide={slide} />
            <div className="flex space-x-5 justify-center mt-10">
                <CircleButton setNum={1} setSlide={setSlide} slide={slide} />
                <CircleButton setNum={2} setSlide={setSlide} slide={slide} />
                <CircleButton setNum={3} setSlide={setSlide} slide={slide} />
                <CircleButton setNum={4} setSlide={setSlide} slide={slide} />
                <CircleButton setNum={5} setSlide={setSlide} slide={slide} />
            </div>
        </div>
    )
}

interface SlideProps {
    slide: number,
}

export const Slide: React.FC<SlideProps> = ({ slide }) => {
    const category: Category | undefined = Categories.find(category => category.id === slide);

    return (
        <div className="flex flex-col">
            <div className='mx-auto text-4xl lg:text-6xl font-medium border-b-[#3719EB] border-b-[10px] lg:border-b-[20px] p-2 lg:p-4 rounded-full'>
                {category?.name}
            </div>
            <div className='mt-5'>
                <img src={process.env.PUBLIC_URL + category?.images[0]} alt="mekke_bonbony" className="w-3/5 lg:w-1/5 aspect-square object-cover object-left mx-auto border border-black border-2 rounded" />
            </div>
        </div>
    )
}

interface CircleButtonProps {
    setSlide: (slide: number) => void,
    setNum: number,
    slide: number
}

export const CircleButton: React.FC<CircleButtonProps> = ({ setSlide, setNum, slide }) => {
    return (
        <button className={`${slide === setNum ? 'bg-blue-700': 'bg-blue-300'} rounded-full w-[30px] h-[30px]`}
            onClick={() => setSlide(setNum)} />
    )
}