import products from '../assets/products.json'
import categories from '../assets/categories.json'
import { useParams } from 'react-router';
import { Product } from '../models/product';
import '../css/image.css'
import { Link } from 'react-router-dom';

export const CategoryPage = () => {
    const { id } = useParams<{ id: string }>();
    const category = categories.find(category => category.id === +(id ?? 0));
    const categoryProducts = products.filter(product => product.categoryId === +(id ?? 0));

    if (!category) {
        return (
            <div>
                404
            </div>
        )
    }

    return (
        <div className="flex flex-col items-center">
            <div className="w-full h-[20vh]">
                <img src={process.env.PUBLIC_URL + category.images[0]} alt="bg" className="w-full h-full object-cover" />
            </div>
            <div className="flex justify-center w-full mt-6">
                <div className="text-4xl border-[#3719EB] border-b-[20px] border-r-[20px] p-2 rounded-full flex justify-center items-center">{category?.name}</div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 px-2'>
                {
                    categoryProducts.map((product, key) => (
                        <CategoryItem product={product} key={key} />
                    ))
                }
            </div>
            <div className="w-full h-[20vh] mt-10">
                <img src={process.env.PUBLIC_URL + category.images[1]} alt="bg" className="w-full h-full object-cover" />
            </div>
        </div>
    )
}

interface CategoryItemProps {
    product: Product,
}

export const CategoryItem: React.FC<CategoryItemProps> = ({ product }) => {
    const weight = product.weight;
    // const color = weight === 90 ? "[#3719EB]" :
    //     weight === 500 ? "[#EB831C]" :
    //         weight === 1000 ? "[#941F0D]" :
    //             weight === 700 ? "[#0B8A18]" :
    //                 weight === 15 ? "[#5D035E]" :
    //                     weight === 170 ? "[#035E4E]" :
    //                         weight === 275 ? "[#545E03]" : "[#23035E]";

    const textColor = weight === 90 ? "text-[#3719EB]" :
        weight === 500 ? "text-[#EB831C]" :
            weight === 1000 ? "text-[#941F0D]" :
                weight === 700 ? "text-[#0B8A18]" :
                    weight === 15 ? "text-[#035E4E]" :
                        weight === 170 ? "text-[#3719EB]" :
                            weight === 275 ? "text-[#545E03]" : "text-[#23035E]";

    const borderColor = weight === 90 ? "border-[#3719EB]" :
        weight === 500 ? "border-[#EB831C]" :
            weight === 1000 ? "border-[#941F0D]" :
                weight === 700 ? "border-[#0B8A18]" :
                    weight === 15 ? "border-[#035E4E]" :
                        weight === 170 ? "border-[#3719EB]" :
                            weight === 275 ? "border-[#545E03]" : "border-[#23035E]";

    const bgColor = weight === 90 ? "bg-[#3719EB]" :
        weight === 500 ? "bg-[#EB831C]" :
            weight === 1000 ? "bg-[#941F0D]" :
                weight === 700 ? "bg-[#0B8A18]" :
                    weight === 15 ? "bg-[#035E4E]" :
                        weight === 170 ? "bg-[#3719EB]" :
                            weight === 275 ? "bg-[#545E03]" : "bg-[#23035E]";

    return (
        <Link to={"/products/" + product.id}>
            <div className={`flex items-start border-8 ${borderColor} shadow-2xl rounded-lg`}>
                <div className=''>
                    <img src={process.env.PUBLIC_URL + product.image} alt={product.name} className='h-[20vh] p-5' />
                </div>
                <div className='flex-1 flex flex-col justify-between items-end'>
                    <div className={`text-right pr-3 py-4 md:p-5 text-xl font-semibold ${textColor}`}>
                        {product.name}
                    </div>
                    <div className={`p-5 ${bgColor} rounded-l-full text-white`}>
                        {product.weight}g
                    </div>
                </div>
            </div>
        </Link>
    )
}
