import '../../css/background.css'

export const AboutUs = () => {
    return (
        <div className='flex flex-col about_us_background p-10 pb-20'>
            <div className='text-white mx-auto text-6xl'>
                Kdo jsme?
            </div>
            <div className="flex flex-col lg:flex-row items-center mt-10">
                <div className="w-full lg:w-1/2 px-2 md:px-10 text-white text-center text-md lg:text-xl">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare vitae sem vitae elementum. Proin vitae mi non lacus faucibus luctus vitae nec ligula. Fusce commodo lacus vitae odio tincidunt, et dignissim nisl sollicitudin. Curabitur iaculis volutpat feugiat. In nec tristique nibh. Donec ut interdum est, id semper nunc. Nunc sit amet purus neque. Cras efficitur vehicula risus, eget aliquet felis rhoncus ut. Aenean vel blandit libero. Sed non ultrices risus, eu sagittis arcu. Curabitur at pharetra turpis. Duis quis dui nulla. Vestibulum ut leo ullamcorper, vestibulum dui a, pharetra nunc. Suspendisse erat libero, consectetur tincidunt risus ut, ultrices faucibus.
                </div>
                <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:px-20">
                    <img src={process.env.PUBLIC_URL + "/apple.jpg"} alt="apple" className='mx-auto object-cover border border-white border-4 w-3/4' />
                </div>
            </div>
        </div>
    )
}